export default function parseLink(link: string) {
    const config = useRuntimeConfig();
    const backEndurl = config.public.BACK_END_URL;
    const frontendUrl = config.public.FRONT_END_URL;
    if (link) {
        const getslug = link.replace(backEndurl, "").replace('https://sasa.co.il', "").replace('https://www.sasa.co.il', "");
        return getslug;
    }
    return "";
}
